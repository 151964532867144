import { AnalyticsService } from './analytics.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
// import * as stopword from 'stopword';
import { removeStopwords } from 'stopword';

import firebase from 'firebase/compat/app';
import { take, map } from 'rxjs/operators';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import Fuse from 'fuse.js';
import * as retailSearch from './../json/retail-search.json';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  HTTP_URL = 'https://us-east4-russells-web.cloudfunctions.net/contact/';

  themeItems = [];
  themeGalleryURL = '';

  _siteSearch: any = null;
  _siteContent = retailSearch as any;

  _remoteConfigInitialized = false;
  isBrowser = false;

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
    private remoteConfig: AngularFireRemoteConfig,
    private analytics: AnalyticsService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  async searchFaq(input: string): Promise<any> {
    let cleaned = this.sterilizeSearch(input);
    await this.analytics.logEvent('search_faq', {
      question: input.toLowerCase(),
      terms: cleaned
    });
    if (cleaned.length < 1) {
      cleaned = input.split(' ');
    }
    return this.afs
      .collection('faqs', (ref) => {
        return ref.where('searchTerms', 'array-contains-any', cleaned).limit(5);
      })
      .get()
      .pipe(
        take(1),
        map((actions) => {
          return actions.docs.map((a) => {
            return a.data();
          });
        })
      )
      .toPromise();
  }

  async upVoteFaq(faq: any): Promise<void> {
    return this.afs
      .collection('faqs')
      .doc(faq.id)
      .update({
        upVotes: firebase.firestore.FieldValue.increment(1),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      });
  }

  private sterilizeSearch(searchTerm: string): string[] {
    const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
    return removeStopwords(searchTerm.toLowerCase().replace(regex, '').split(' '));
  }

  async sendContactForm(formData): Promise<string> {
    const resp = (await this.http
      .post(
        `${this.HTTP_URL}contact`,
        { ...formData, createdAt: new Date(), updatedAt: new Date() },
        this.getDefaultHttpOptions()
      )
      .toPromise()) as any;
    await this.analytics.logEvent('submit_contact_form', {
      contactFormType: formData.type,
      message: formData.message
    });
    return resp.message;
  }

  private getDefaultHttpOptions(): any {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json; charset=utf-8');

    const httpOptions = {
      headers
    };
    return httpOptions;
  }

  async getRemoteConfigData(key: string): Promise<any> {
    if (this.isBrowser) {
      if (!this._remoteConfigInitialized) {
        await this.remoteConfig.ensureInitialized();
        await this.remoteConfig.fetchAndActivate();
        this._remoteConfigInitialized = true;
      }
      const val = await this.remoteConfig.getValue(key);
      return val ? val.asString() : '';
    }
    return '';
  }

  async getSiteSearchResults(searchTerms: string, maxScore = 0.3): Promise<any[]> {
    let fuse = this._siteSearch;
    if (!fuse) {
      this._siteSearch = new Fuse(this._siteContent.default.data, {
        includeScore: true,
        includeMatches: true,
        findAllMatches: false,
        ignoreLocation: true,
        isCaseSensitive: false,
        shouldSort: true,
        keys: [
          'text',
          { name: 'h1', weight: 0.9 },
          { name: 'metaTitle', weight: 0.9 },
          { name: 'metaDescription', weight: 0.85 },
          { name: 'h2', weight: 0.75 },
          { name: 'h3', weight: 0.5 }
        ]
      });
      fuse = this._siteSearch;
    }
    const term = this.sterilizeSearch(searchTerms).join(' ');
    const res: any[] = fuse.search(term) || [];
    return res.filter((r) => {
      if (r.score < maxScore) {
        return true;
      }
      return false;
    });
  }
}
