<nb-form-field class="text-center">
  <label aria-label="product-search" for="auto-input">Search</label>
  <input
    #autoInput
    type="text"
    fullWidth
    placeholder="What are you looking for?"
    status="primary"
    shape="round"
    nbInput
    aria-autocomplete="list"
    aria-label="product-search"
    [fieldSize]="fieldSize"
    (input)="onChange()"
    (keyup.enter)="onSelectionChange(autoInput.value)"
    [nbAutocomplete]="auto"
    name="auto-input"
    id="auto-input"
  />
  <button
    nbSuffix
    shape="round"
    status="success"
    (click)="onSelectionChange(autoInput.value)"
    nbButton
  >
    <nb-icon icon="search"></nb-icon>
  </button>
</nb-form-field>

<nb-autocomplete [size]="'giant'" #auto (selectedChange)="onSelectionChange($event)">
  <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option">
    {{ option }}
  </nb-option>
</nb-autocomplete>
