import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  CartItem,
  CheckoutSession,
  DeliveryAddress,
  DeliveryRule,
  DeliverySlot,
  DeliverySlotOpening,
  GalleryRecord,
  initShoppingCart,
  ShoppingCart,
  ShopSettings
} from 'shared';
import { AuthService } from '../services/auth.service';
import firebase from 'firebase/compat/app';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { GuestShopLoginComponent } from './shop-components/guest-shop-login/guest-shop-login.component';
import { HelpService } from '../services/help.service';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { AnalyticsService } from '../services/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class ShopService {
  protected _API_URL = 'https://russellsny.com/shop-api';
  private _cart = new BehaviorSubject<ShoppingCart>({
    items: [],
    deliveryAddress: {}
  } as ShoppingCart);
  _cartSubInit = false;

  private _shopSettings = new BehaviorSubject<ShopSettings>({
    isShopEnabled: true,
    isGiftCardPurchasingEnabled: true,
    excludedDeliveryDates: [],
    deliverySeasonStart: new Date(),
    deliverySeasonEnd: new Date()
  });
  private _deliveryRules = new BehaviorSubject<DeliveryRule[]>([]);

  private _cart$ = this._cart.asObservable().pipe(shareReplay(1));
  private _settings$ = this._shopSettings.asObservable().pipe(shareReplay(1));
  private _deliveryRules$ = this._deliveryRules.asObservable().pipe(shareReplay(1));

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private dialogService: NbDialogService,
    private toastService: NbToastrService,
    private analyticsService: AnalyticsService,
    private helpService: HelpService,
    private afs: AngularFirestore
  ) {
    this.helpService.getRemoteConfigData('store_settings').then((res) => {
      if (res) {
        if (!environment.production) {
          this._shopSettings.next({
            isShopEnabled: true,
            isGiftCardPurchasingEnabled: true,
            excludedDeliveryDates: [],
            deliverySeasonStart: new Date(),
            deliverySeasonEnd: new Date()
          });
        } else {
          this._shopSettings.next(JSON.parse(res));
        }
      }
    });
    this.afs
      .collection<DeliveryRule>('delivery-rules', (ref) => {
        return ref.limit(10);
      })
      .valueChanges()
      .subscribe((rules) => {
        this._deliveryRules.next(rules);
      });
  }

  async initCartSubscription() {
    if (this._cartSubInit) {
      return;
    }
    try {
      this._cartSubInit = true;
      const user = await this.authService.$user.pipe(take(1)).toPromise();
      let tmpCart = await this.activeCart$().pipe(take(1)).toPromise();
      if (user) {
        this.authService.$user
          .pipe(
            takeUntil(this.authService.logout$),
            switchMap((user) => {
              if (user && user.uid) {
                return this.afs
                  .collection('shopping-carts')
                  .doc<ShoppingCart>(user.uid)
                  .valueChanges();
              }
              return this.activeCart$();
            }),
            takeUntil(this.authService.logout$),
            shareReplay(1)
          )
          .subscribe(
            async (cart) => {
              if (cart !== this._cart.value) {
                this._cart.next(cart);
              }
              if (tmpCart && tmpCart.items.length > 0 && !tmpCart.id) {
                // add temp to actual cart
                const items = tmpCart.items;
                tmpCart = null;
                await this.addToCart(items, true);
              }
            },
            async (error) => {
              // likely missing a cart. initalize a new one..
              console.log('Missing Cart');
              this._cartSubInit = false;
              await this.initializeNewCart();
            },
            () => {
              console.log('COMPLETE');
              this._cartSubInit = false;
              this.resetCart();
            }
          );
      } else {
        this._cartSubInit = false;
      }
    } catch (error) {
      console.error('err gettin user', error);
    }
  }

  async initializeNewCart() {
    const user = await this.authService.$user.pipe(take(1)).toPromise();
    // create cart on database
    const newCart: ShoppingCart = initShoppingCart({
      id: user.uid,
      isGuestCart: user.isAnonymous,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: user.uid,
      updatedBy: user.uid
    });
    await this.afs.collection('shopping-carts').doc(user.uid).set(newCart);
    await this.initCartSubscription();
  }

  activeCart$() {
    return this._cart$;
  }

  shopSettings$() {
    return this._settings$;
  }

  deliveryRules$() {
    return this._deliveryRules$;
  }

  resetCart() {
    this._cart.next({ items: [], deliveryAddress: {} } as ShoppingCart);
  }

  async addToCart(newCartItems: CartItem[], forceQuantities = false): Promise<boolean> {
    // check if logged in.
    const user = await this.authService.$user.pipe(take(1)).toPromise();
    const currentCart = await this.activeCart$().pipe(take(1)).toPromise();
    await this.analyticsService.logEvent('add_to_cart');
    if (user && !this._cartSubInit) {
      // user logged in but cart service hasn't been initialized
      this._cart.next({ ...currentCart, items: [...currentCart.items, ...newCartItems] });
      await this.initCartSubscription();
      return true;
    } else {
      if (!user || !currentCart.id || currentCart.id === '') {
        // cache in temp cart
        this._cart.next({ ...currentCart, items: [...currentCart.items, ...newCartItems] });
        // user needs to log in as user or guest
        this.dialogService.open(GuestShopLoginComponent, {});
        return false;
      } else {
        // popup on completion
        const endpoint = forceQuantities
          ? `${this._API_URL}/cart/${currentCart.id}/qtys`
          : `${this._API_URL}/cart/${currentCart.id}/items`;
        await this.http
          .put(endpoint, {
            items: newCartItems
          })
          .pipe(take(1))
          .toPromise();
        return true;
      }
    }
  }

  async removeFromCart(cartItemId: string) {
    await this.analyticsService.logEvent('remove_from_cart', { currency: 'USD' });
    const currentCart = await this.activeCart$().pipe(take(1)).toPromise();
    const endpoint = `${this._API_URL}/cart/${currentCart.id}/items/${cartItemId}`;
    if (currentCart.id && currentCart.id !== '') {
      await this.http.delete(endpoint).pipe(take(1)).toPromise();
    }
    return;
  }

  async updateCartDeliverySlots(address?: DeliveryAddress) {
    const currentCart = await this.activeCart$().pipe(take(1)).toPromise();
    if ((!currentCart.deliveryAddress || !currentCart.deliveryAddress.zip) && !address) {
      return;
    }
    const endpoint = `${this._API_URL}/delivery/slots/${currentCart.id}`;
    if (currentCart.id && currentCart.id !== '') {
      await this.http
        .put(endpoint, {
          address: address ? address : currentCart.deliveryAddress
        })
        .pipe(take(1))
        .toPromise();
    }
    return;
  }

  async getAvailableDeliverySlots(): Promise<DeliverySlotOpening[]> {
    const endpoint = `${this._API_URL}/delivery/slots/availability`;
    return this.http
      .get(endpoint)
      .pipe(
        take(1),
        map((res: any) => {
          return res.slots;
        })
      )
      .toPromise();
  }

  async holdDeliveryDateSlots(requestedSlots: DeliverySlot[]) {
    const endpoint = `${this._API_URL}/delivery/date/hold`;
    return this.http.put(endpoint, { requestedSlots }).pipe(take(1)).toPromise();
  }

  async releaseDeliveryDateSlots(releasedSlots: DeliverySlot[]) {
    const endpoint = `${this._API_URL}/delivery/date/release`;
    return this.http.put(endpoint, { releasedSlots }).pipe(take(1)).toPromise();
  }

  async createCheckoutSession(): Promise<CheckoutSession> {
    const endpoint = `${this._API_URL}/checkout`;
    return this.http.post<CheckoutSession>(endpoint, {}).pipe(take(1)).toPromise();
  }

  async checkOrderStatus(searchTerm: string): Promise<any> {
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const params = {};
    searchTerm = searchTerm.toLowerCase();
    if (searchTerm && emailRegex.test(searchTerm)) {
      params['email'] = searchTerm;
    } else {
      params['orderid'] = searchTerm;
    }
    const endpoint = `${this._API_URL}/checkout-session/summaries`;
    return this.http
      .get<any>(endpoint, { params })
      .pipe(
        take(1),
        map((res) => {
          return res.data?.summaries;
        })
      )
      .toPromise();
  }

  // return rules eligible for given zip
  async getDeliveryRules(zip: string): Promise<DeliveryRule[]> {
    const endpoint = `${this._API_URL}/delivery/rules`;
    return this.http
      .get(endpoint, {
        params: {
          zip
        }
      })
      .pipe(
        take(1),
        map((res: any) => {
          return res.rules;
        })
      )
      .toPromise();
  }

  getCategoryShopItemScrollerObservable(
    categories: string[],
    rowItems = 3,
    cursors?: Map<number, any[]>
  ): Observable<GalleryRecord[][]> {
    return this.afs
      .collection<GalleryRecord>('gallery-records', (ref) => {
        let r = ref
          .where('hasLineItems', '==', true)
          .where('category', 'in', categories)
          .orderBy('name', 'asc');
        if (cursors && cursors.has(0)) {
          const cursor = cursors.get(0).pop().name;
          r = r.startAfter(cursor);
        }
        return r.limit(20);
      })
      .valueChanges()
      .pipe(
        map((rec) => {
          let row = [];
          const records: GalleryRecord[][] = [];
          rec.forEach((r, index) => {
            if (index !== 0 && index % rowItems === 0) {
              records.push([...row]);
              row = [];
            }
            row.push(r);
          });
          if (row.length > 0) {
            records.push([...row]);
          }
          return records;
        })
      );
  }

  isValidItemQuantity(item: CartItem, deliveryRule: DeliveryRule) {
    if (
      item.quantity < item.minQty ||
      item.quantity > item.maxQty ||
      (item.retrievalMethodType === 'delivery' && item.quantity < deliveryRule?.minQty) ||
      (item.retrievalMethodType === 'delivery' && item.quantity > deliveryRule?.maxQty)
    ) {
      let message = `Cart item has a min limit of ${item.minQty} and a max limit of ${item.maxQty}.`;
      if (item.retrievalMethodType === 'delivery') {
        message += ` On delivery of this type of item, there is a min limit of ${deliveryRule?.minQty} and max limit of ${deliveryRule?.maxQty}.`;
      }
      this.toastService.danger(message, 'Issue updating cart item.', {
        duration: 15000
      });
      return false;
    }
    return true;
  }
}
