import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { AuthService } from './../services/auth.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { NbToastrService } from '@nebular/theme';
import { environment } from './../../environments/environment';
import { ShopService } from '../shop/shop.service';
import { ShopSettings } from 'shared';

@Injectable()
export class FirebaseAuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private shopService: ShopService,
    private toastService: NbToastrService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Get the auth token from the service.
    let shopSettings: ShopSettings = {} as ShopSettings;
    return req.url.indexOf('shop-api') < 0
      ? next.handle(req)
      : from(this.shopService.shopSettings$()).pipe(
          switchMap((settings) => {
            shopSettings = settings;
            return this.authService.getIdTokenResult();
          }),
          switchMap((tok) => {
            // Clone the request and replace the original headers with
            // cloned headers, updated with the authorization.
            this.spinner.show();
            const authReq = req.clone({
              headers: req.headers
                .set('Authorization', `Bearer ${tok?.token || ''}`)
                .set(
                  'Stripe-Environment',
                  environment.production ? 'production' : environment.qa ? 'qa' : 'development'
                )
                .set('Shop-Enabled', shopSettings.isShopEnabled.toString())
                .append('Content-Type', 'application/json')
            });

            // send cloned request with header to the next handler.
            return next.handle(authReq);
          }),
          tap((evt) => {
            if (evt instanceof HttpResponse) {
              // response
              this.spinner.hide();
              this.toastService.success(`${evt.body?.message}`, 'Success', { duration: 3000 });
            }
          }),
          catchError((err) => {
            if (err instanceof HttpErrorResponse) {
              this.spinner.hide();
              if (err && err.status > 499) {
                this.toastService.danger(`${err.error?.message}`, 'Whoops!', { duration: 6000 });
              } else {
                this.toastService.info(`${err.error?.message}`, 'Issue', { duration: 7000 });
              }
            }
            return of(err.error);
          })
        );
  }
}
