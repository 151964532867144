import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './pages/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule)
  },
  {
    path: 'analysis',
    loadChildren: () => import('./pages/analysis/analysis.module').then((m) => m.AnalysisModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then((m) => m.ContactModule)
  },
  {
    path: 'delivery',
    loadChildren: () => import('./pages/delivery/delivery.module').then((m) => m.DeliveryModule)
  },
  {
    path: 'design',
    loadChildren: () => import('./design/design.module').then((m) => m.DesignModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./pages/gallery/gallery.module').then((m) => m.GalleryModule),
    data: {
      title: 'Product Gallery',
      description: `Our gallery features trees, shrubs, flowers, mulch, stone, and more that you can find at our garden center.`,
      imageURL:
        'https://firebasestorage.googleapis.com/v0/b/russells-web.appspot.com/o/collections%2Fgallery-records%2FjMvB1Jza5o9aLzcT68Au%2Fthumb%40512_1597250995942_image.jpg?alt=media&token=38cb65b8-ff74-47e3-b9f5-20e389191377'
    }
  },
  {
    path: 'giftcards',
    loadChildren: () => import('./pages/giftcards/giftcards.module').then((m) => m.GiftcardsModule)
  },
  {
    path: 'hardscapes',
    loadChildren: () =>
      import('./pages/hardscapes/hardscapes.module').then((m) => m.HardscapesModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/history/history.module').then((m) => m.HistoryModule)
  },
  {
    path: 'inventory',
    loadChildren: () => import('./pages/inventory/inventory.module').then((m) => m.InventoryModule)
  },
  {
    path: 'jobs',
    loadChildren: () => import('./pages/jobs/jobs.module').then((m) => m.JobsModule)
  },
  {
    path: 'outdoorliving',
    loadChildren: () =>
      import('./pages/outdoor-living/outdoor-living.module').then((m) => m.OutdoorLivingModule)
  },
  {
    path: 'natives',
    loadChildren: () => import('./pages/natives/natives.module').then((m) => m.NativesModule)
  },
  {
    path: 'resources',
    loadChildren: () => import('./pages/resources/resources.module').then((m) => m.ResourcesModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then((m) => m.SearchModule)
  },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then((m) => m.ShopModule)
  },
  {
    path: 'specials',
    loadChildren: () => import('./pages/specials/specials.module').then((m) => m.SpecialsModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then((m) => m.TermsModule)
  },
  {
    path: 'warranty',
    loadChildren: () => import('./pages/warranty/warranty.module').then((m) => m.WarrantyModule)
  },
  {
    path: 'wholesale',
    loadChildren: () => import('./wholesale/wholesale.module').then((m) => m.WholesaleModule)
  },
  {
    path: 'wholesale-policy',
    loadChildren: () =>
      import('./pages/wholesale-policy/wholesale-policy.module').then(
        (m) => m.WholesalePolicyModule
      )
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
