import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Account } from 'shared';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  $user: Observable<firebase.User> = this.afAuth.authState;

  logout$ = new Subject<boolean>();
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {}

  async createUserWithEmailAndPassword(account: Account, password: string): Promise<void> {
    await this.afAuth.createUserWithEmailAndPassword(account.email, password);
    const currentUser = await this.afAuth.currentUser;
    await currentUser.sendEmailVerification();
    account.displayName =
      account.displayName.charAt(0).toUpperCase() + account.displayName.substring(1);
    await currentUser.updateProfile({
      displayName: account.displayName
    });
    await this.afs
      .collection('wholesale-registrations')
      .doc(currentUser.uid)
      .set({
        ...account,
        id: currentUser.uid,
        createdBy: currentUser.uid,
        createdAt: new Date(),
        updatedAt: new Date(),
        updatedBy: currentUser.uid
      });
    await this.router.navigateByUrl('/wholesale/auth');
    return this.logout();
  }

  async createAnonymousUser() {
    const anonUser = await this.afAuth.signInAnonymously();
    console.log(anonUser);
  }

  async sendEmailVerification(): Promise<void> {
    const currentUser = await this.afAuth.currentUser;
    return currentUser.sendEmailVerification();
  }

  loginWithEmail(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  logout(): Promise<void> {
    this.logout$.next(true);
    return this.afAuth.signOut();
  }

  requestEmailPasswordLink(email: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  async refreshUser(): Promise<void> {
    const currentUser = await this.afAuth.currentUser;
    if (currentUser) {
      await currentUser.getIdToken(true);
      await currentUser.reload();
    }
  }

  async getIdTokenResult(): Promise<firebase.auth.IdTokenResult> {
    const currentUser = await this.afAuth.currentUser;
    return currentUser ? currentUser.getIdTokenResult() : null;
  }
}
