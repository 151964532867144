import { AnalyticsService } from '../../../../services/analytics.service';
import { GALLERY_COLLECTIONS } from 'shared';
import { Router } from '@angular/router';
import { GalleryService } from '../../gallery.service';
import { debounceTime, map } from 'rxjs/operators';
import { Component, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-gallery-searchbar',
  templateUrl: './gallery-searchbar.component.html',
  styleUrls: ['./gallery-searchbar.component.scss']
})
export class GallerySearchbarComponent implements OnInit {
  options: string[] = [];
  filteredOptions$: Observable<string[]>;

  @ViewChild('autoInput') input;

  @Input() fieldSize = 'giant';
  @Output() searchTerm = new EventEmitter<string>();

  constructor(
    private galleryService: GalleryService,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.filteredOptions$ = of(this.options);
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return this.galleryService.keywordSearch(value, 3).pipe(
      debounceTime(1000),
      map((records) => {
        return records.map((record) => {
          return record.name;
        });
      })
    );
  }

  onChange(): void {
    this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
  }

  async onSelectionChange($event: string): Promise<void> {
    if (!$event || $event === '' || $event.length < 2) {
      return;
    }

    await this.analyticsService.logEvent('search', {
      search_term: $event.toLowerCase()
    });
    if (this.router.url.indexOf('gallery') > -1) {
      await this.analyticsService.logEvent('search_gallery', {
        galleryQuery: $event.toLowerCase()
      });
    }
    // check if we match a collection or gallery
    $event = $event.toLowerCase();
    let singular = '';
    if ($event.substring($event.length - 1) === 's') {
      // last char is s, possible plural
      singular = $event.substring(0, $event.length - 1);
    }
    let match = `/gallery/`;
    let matches = 0;
    Object.keys(GALLERY_COLLECTIONS).some((collectionKey) => {
      const collection = GALLERY_COLLECTIONS[collectionKey];
      if ([$event, singular].indexOf(collection.name.toLowerCase()) > -1) {
        // match
        if (matches < 1) {
          match += `collection/${collectionKey}`;
        }
        matches++;
        return true;
      } else {
        collection.categories.some((category) => {
          if ([$event, singular].indexOf(category.toLowerCase()) > -1) {
            // match
            if (matches < 1) {
              match += `collection/${collectionKey}/category/${category}`;
            }
            matches++;
            return true;
          }
        });
      }
    });

    if (match !== '/gallery/' && matches === 1) {
      // route to a collection or category if there is only matches
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      await this.router.navigateByUrl(match.toLowerCase());
      return;
    }

    const vaguePlantSearches = ['shrub', 'tree', 'bush', 'flower'];

    // go to searchpage
    if (
      $event.split(' ').length < 2 &&
      vaguePlantSearches.some((term) => {
        return $event.indexOf(term) > -1 || singular.indexOf(term) > -1;
      })
    ) {
      // interrupt vague searches to direct to collection
      await this.router.navigate(['/gallery/collection/plants']);
    } else {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      await this.router.navigate(['/search'], {
        queryParams: {
          q: singular ? singular : $event
        }
      });
    }

    this.searchTerm.emit($event);
  }
}
