import { AngularFirestore } from '@angular/fire/compat/firestore';
import { take } from 'rxjs/operators';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { NavigationStart, RouterEvent, Router, NavigationEnd } from '@angular/router';
import { AuthService } from './auth.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';

declare let newrelic;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private _navStartUrl = '';
  private _navEndUrl = '';
  isBrowser = false;
  constructor(
    private authService: AuthService,
    private afs: AngularFirestore,
    private analytics: AngularFireAnalytics,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.router.events.subscribe(
      (e: RouterEvent) => {
        if (e instanceof NavigationStart) {
          this._navStartUrl = e.url;
          this.analytics.setCurrentScreen(e.url);
          this.logEvent('page_view', { page: e.url }, false);
          this.newRelicInteraction(e.url);
        }
        if (e instanceof NavigationEnd && this._navStartUrl !== e.url) {
          this._navEndUrl = e.url;
          this.analytics.setCurrentScreen(e.url);
          this.logEvent('page_view', { page: e.url }, false);
          this.newRelicInteraction(e.url);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  enable(): Promise<void> {
    this.analytics.setUserId('VISITOR');
    return this.analytics.setAnalyticsCollectionEnabled(true);
  }

  getNavUrls() {
    return { start: this._navStartUrl, end: this._navEndUrl };
  }

  newRelicInteraction(name: string) {
    if (this.isBrowser) {
      newrelic.setPageViewName(name, environment.production ? 'russellsny.com' : 'development');
      newrelic.setCurrentRouteName(name);
      let i = newrelic.interaction();
      i.setName(name);
      i.save();
    }
  }

  async logEvent(eventName: string, data: any = {}, persistToDb = true): Promise<void> {
    await this.analytics.logEvent(eventName, data);
    const user = (await this.authService.$user.pipe(take(1)).toPromise()) || ({} as any);
    if (persistToDb) {
      const event: any = {
        collection: 'user-analytic-events',
        eventName,
        data,
        auth: user.uid || 'retail',
        name: user.displayName || 'retail',
        isWholesaleSegment: user.uid ? true : false,
        id: this.afs.createId(),
        createdAt: new Date()
      } as any;
      await this.afs.collection('user-analytic-events').doc(event.id).set(event);
    }
    return;
  }
}
