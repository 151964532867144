<nb-layout withScroll restoreScrollTop center #layout>
  <nb-layout-header *ngIf="shellInitialized">
    <div class="row top-xs">
      <div class="col-xs-5 col-sm-3">
        <div class="row start-xs middle-xs">
          <div class="col-xs-3" *ngIf="isHandset">
            <a (click)="toggleSidebar()" href="#" aria-label="menu" class="sidebar-toggle">
              <nb-icon status="control" icon="menu-2-outline"></nb-icon>
            </a>
          </div>
          <div class="col-xs-9 col-sm-5">
            <img
              [routerLink]="['/']"
              alt="russells-logo"
              class="logo-nav"
              src="assets/images/logo_nav.png"
            />
          </div>
          <div class="col-xs col-sm-7" *ngIf="!isHandset">
            <app-hours-status></app-hours-status>
          </div>
        </div>
      </div>
      <div class="col-xs-7 col-sm-offset-1 col-sm-8">
        <div class="row end-xs middle-xs" *ngIf="!isHandset">
          <div class="col-xs-12">
            <div class="white address">
              9800 Transit Road East Amherst, NY 14051 |
              <a href="#" routerLink="/contact">Contact Us</a>
            </div>
          </div>
        </div>
        <div class="row end-xs middle-xs">
          <div class="col-xs" *ngIf="!isHandset">
            <app-gallery-searchbar [fieldSize]="'small'"></app-gallery-searchbar>
          </div>
          <div class="col-xs-6 col-sm-4">
            <ng-container *ngIf="authService.$user | async as user">
              <nb-user
                [size]="(layoutService.$isHandset | async) ? 'small' : 'small'"
                [onlyPicture]="false"
                title="My Account"
                [name]="user.isAnonymous ? 'Guest' : user.displayName"
                [picture]="user.photoURL"
                [nbContextMenuPlacement]="'bottom'"
                [nbContextMenuTag]="'user-menu'"
                [nbContextMenu]="userMenu"
              >
              </nb-user>
            </ng-container>
          </div>
          <div *ngIf="isShopEnabled" class="col-xs-6 col-sm-2">
            <app-cart-button></app-cart-button>
          </div>
        </div>
      </div>
    </div>
  </nb-layout-header>
  <nb-layout-header *ngIf="shellInitialized" subheader [fixed]="isHeaderFixed" class="nav-header">
    <div class="menu-wrapper">
      <nb-menu
        *ngIf="!isHandset"
        autoCollapse="true"
        class="menu-horizontal"
        skipLocationChange="true"
        [items]="menu"
      ></nb-menu>
      <div class="text-center padding-around padding-top" *ngIf="isHandset">
        <app-gallery-searchbar
          [fieldSize]="'small'"
          *ngIf="router.url.indexOf('gallery') < 0"
        ></app-gallery-searchbar>
        <app-hours-status></app-hours-status>
      </div>
      <app-cart-button *ngIf="isHeaderFixed && isShopEnabled"></app-cart-button>
    </div>
  </nb-layout-header>
  <nb-sidebar fixed tag="menu-sidebar" compactedBreakpoints="[]" state="collapsed" #sidebar>
    <button size="giant" class="sidebar-menu-close" (click)="toggleSidebar()" nbButton ghost>
      <nb-icon icon="close"></nb-icon>
    </button>
    <nb-menu class="sidebar-menu" autoCollapse="true" [items]="menu"></nb-menu>
  </nb-sidebar>

  <nb-layout-column>
    <ng-container *ngIf="authService.$user | async as user">
      <app-alt-menu *ngIf="!user.isAnonymous"></app-alt-menu>
    </ng-container>
    <router-outlet></router-outlet>
  </nb-layout-column>

  <nb-layout-footer>
    <div class="row row-footer">
      <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-3">
          <p class="h6">About</p>
          <p>
            <a routerLink="/jobs">Careers</a>
          </p>
          <p>
            <a routerLink="/contact/directions">Directions</a>
          </p>
          <p>
            <a routerLink="/history">History</a>
          </p>
          <div class="carousel-wrapper">
            <app-hours-status #footerHours></app-hours-status>
          </div>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-3">
          <p class="h6">Info & Services</p>
          <p>
            <a routerLink="/design">Design Program</a>
          </p>
          <p>
            <a routerLink="/gallery">Gallery</a>
          </p>
          <p>
            <a routerLink="/resources/guides">Guides & Tutorials</a>
          </p>
          <p>
            <a routerLink="/resources/calculators/mulch">Mulch Calculator</a>
          </p>
          <p>
            <a routerLink="/specials">Weekly Specials</a>
          </p>
          <p>
            <a routerLink="/wholesale">Wholesale Corner</a>
          </p>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-3">
          <p class="h6">Customer Service</p>
          <p>
            <a routerLink="/contact">Contact Us</a>
          </p>
          <p>
            <a routerLink="/delivery">Delivery</a>
          </p>
          <p>
            <a routerLink="/contact/faq">Help & FAQ</a>
          </p>
          <p>
            <a routerLink="/shop/status">Online Order Status</a>
          </p>
          <p>
            <a routerLink="/warranty">Returns & Warranty</a>
          </p>
          <p>
            <a routerLink="/wholesale-policy">Wholesale Policy</a>
          </p>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-3 col-full col-social">
          <p class="h6">Connect With Us</p>
          <app-connect-link></app-connect-link>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-terms">
          <p>Russell's Tree & Shrub Farm, LLC</p>
          <p routerLink="terms">Terms & Conditions of Use</p>
        </div>
      </div>
    </div>
  </nb-layout-footer>
</nb-layout>
