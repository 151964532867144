<nb-card>
  <nb-card-header>
    <h1>Log In To Continue</h1>
  </nb-card-header>
  <nb-card-body class="text-center">
    <div class="row">
      <!-- <div class="col-xs">
        <button disabled nbButton size="large" shape="round" status="primary">Sign Up</button>
      </div> -->
      <div class="col-xs">
        <button (click)="guestLogin()" nbButton size="large" shape="round" status="primary">
          Continue As Guest
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <button
          ghost
          nbButton
          size="tiny"
          shape="round"
          status="primary"
          routerLink="/wholesale"
          (click)="dialogRef.close(true)"
        >
          Active wholesale member? Log In.
        </button>
      </div>
      <!-- <div class="col-xs-12">
        <button ghost disabled nbButton size="tiny" shape="round" status="primary">
          Already a member? Log In.
        </button>
      </div> -->
    </div>
  </nb-card-body>
</nb-card>
