import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ShoppingCart } from 'shared';
import { ShopService } from '../../shop.service';

@Component({
  selector: 'app-cart-button',
  templateUrl: './cart-button.component.html',
  styleUrls: ['./cart-button.component.scss']
})
export class CartButtonComponent implements OnInit {
  cart$: Observable<ShoppingCart>;
  cartItemCount = 0;
  constructor(public shopService: ShopService) {}

  ngOnInit(): void {
    this.cart$ = this.shopService.activeCart$().pipe(
      tap(async (cart) => {
        if (cart && !cart.id) {
          await this.shopService.initCartSubscription();
        }
        if (cart) {
          this.cartItemCount = 0;
          cart.items.forEach((item) => {
            this.cartItemCount += item.quantity;
          });
        }
      })
    );
  }
}
