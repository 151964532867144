<ng-container *ngIf="infinite$ | async">
  <virtual-scroller
    #scroll
    *ngIf="isBrowser"
    [items]="docs"
    (vsEnd)="advanceScroller($event)"
    [bufferAmount]="2"
    [parentScroll]="useWindow ? scroll.window : parentContent"
    [horizontal]="isHorizontal"
    [ngClass]="{ horizontal: isHorizontal }"
    [ngStyle]="{
      height: isHorizontal ? recordSize + 15 + 'px' : ''
    }"
  >
    <div
      [ngStyle]="{
        width: isHorizontal ? recordSize + 'px' : 'auto',
        height: !isHorizontal ? recordSize + 'px' : 'auto'
      }"
      *ngFor="let d of scroll.viewPortItems; trackBy: trackByIdx"
    >
      <ng-container *ngTemplateOutlet="child; context: { doc: d }"></ng-container>
    </div>
    <ng-container *ngIf="isEnd && !isHorizontal">
      <div class="row">
        <div class="text-center col-md-8 col-md-offset-2">
          <p>No more results. We're always adding new items!</p>
          <h3>Russell's Tree & Shrub Farm</h3>
        </div>
      </div>
    </ng-container>
  </virtual-scroller>
</ng-container>
