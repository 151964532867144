<!-- carousel -->
<div class="carousel">
  <p class="text-center" *ngIf="slides.length < 1">No Images found</p>
  <ng-container *ngFor="let slide of slides; let i = index">
    <a
      href="{{ slide.url ? slide.url : '' }} "
      [target]="slide.openUrlNewWindow ? '_blank' : '_self'"
      aria-label="slide link"
      title="slide link"
      (click)="!slide.url ? $event.preventDefault() : null"
    >
      <img
        [hidden]="i !== currentSlide"
        alt="homepage slideshow"
        [src]="slide.src || 'assets/images/missing_photo.jpg'"
        class="slide"
        [@slideAnimation]="animationType"
      />
    </a>
  </ng-container>

  <!-- controls -->
  <button aria-label="slider previous" class="control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <button aria-label="slider next" class="control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
</div>
<div class="carousel-markers">
  <ng-container *ngFor="let item of slides; let i = index">
    <button aria-label="slide marker" (click)="goToSlide(i)" nbButton ghost status="success">
      <nb-icon [icon]="i == currentSlide ? 'radio-button-on-outline' : 'radio-button-off-outline'">
      </nb-icon>
    </button>
  </ng-container>
</div>
