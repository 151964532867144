import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Home',
    icon: 'home-outline',
    link: '/',
    home: true
  },
  {
    title: 'Gardening Help',
    icon: 'pantone-outline',
    children: [
      {
        title: 'Calculators',
        children: [
          {
            title: 'Mulch, Soil & Stone',
            link: '/resources/calculators/mulch'
          },
          {
            title: 'Retaining Wall',
            link: '/resources/calculators/wall'
          }
        ]
      },
      {
        title: 'Delivery',
        link: '/delivery'
      },
      {
        title: 'Free Design',
        link: '/design'
      },
      {
        title: 'Guides & Tutorials',
        link: '/resources/guides'
      },
      {
        title: 'Lawn & Plant Analysis',
        link: '/analysis'
      }
    ]
  },
  {
    title: 'Products',
    icon: 'grid-outline',
    link: '/products',
    children: [
      {
        title: 'Popular In-stock',
        link: '/inventory'
      },
      {
        title: 'All Products',
        link: '/gallery',
        pathMatch: 'full'
      },
      {
        title: 'Garden & Lawn Care',
        link: '/gallery/collection/garden and lawn treatment'
      },
      {
        title: 'Gift Cards',
        link: '/giftcards'
      },
      {
        title: 'Mulch, Soil, & Stone',
        link: '/gallery/collection/mulch soil and stone',
        pathMatch: 'full'
      },
      {
        title: 'Natives',
        link: '/natives'
      },
      {
        title: 'Outdoor Living',
        link: '/outdoorliving'
      },
      {
        title: 'Patio, Wall & Walkway',
        link: '/hardscapes'
      },
      {
        title: 'Plants',
        link: '/gallery/collection/plants',
        // link: '/gallery/collection/plants',
        pathMatch: 'full'
      }
    ]
  },
  {
    title: 'Store Info',
    icon: 'info-outline',
    children: [
      {
        title: 'Contact Us',
        link: '/contact'
      },
      {
        title: 'Directions',
        link: '/contact/directions'
      },
      {
        title: 'History',
        link: '/history'
      },
      {
        title: 'Jobs',
        link: '/jobs'
      },
      {
        title: 'Returns & Warranty',
        link: '/warranty'
      },
      {
        title: 'Store Hours'
      }
    ]
  },
  {
    title: 'Specials',
    icon: 'gift-outline',
    link: '/specials'
  }
];
