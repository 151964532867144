import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { CartItem, DeliveryRule } from 'shared';
import { ShopService } from './../../shop.service';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {
  @Input() cartItem: CartItem;

  deliveryRule: DeliveryRule;
  minQty = 1;
  maxQty = 1;
  constructor(private shopService: ShopService) {}

  async ngOnInit(): Promise<void> {
    this.minQty = this.cartItem.minQty;
    this.maxQty = this.cartItem.maxQty;
    if (this.cartItem.retrievalMethodType === 'delivery') {
      const rules = await this.shopService.deliveryRules$().pipe(take(1)).toPromise();
      rules.forEach((r) => {
        if (r.appliedGalleryCategories.indexOf(this.cartItem.galleryCategory) > -1) {
          this.deliveryRule = r;
          this.minQty = Math.max(this.minQty, this.deliveryRule.minQty);
          this.maxQty = Math.min(this.maxQty, this.deliveryRule.maxQty);
        }
      });
    }
  }

  async updateCartItem() {
    if (!this.shopService.isValidItemQuantity(this.cartItem, this.deliveryRule)) {
      return;
    }
    try {
      await this.shopService.addToCart([this.cartItem], true);
      if (
        this.cartItem.retrievalMethodType === 'delivery' ||
        this.cartItem.retrievalMethodType === 'ship'
      ) {
        // update slots
        await this.shopService.updateCartDeliverySlots();
      }
    } catch (error) {}
  }

  async deleteCartItem() {
    try {
      await this.shopService.removeFromCart(this.cartItem.id);
      await this.shopService.updateCartDeliverySlots();
    } catch (error) {}
  }
}
