import { HelpService } from './../../services/help.service';
import { AnalyticsService } from './../../services/analytics.service';
import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { NbDialogContainerComponent, NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-hours-status',
  templateUrl: './hours-status.component.html',
  styleUrls: ['./hours-status.component.scss']
})
export class HoursStatusComponent implements OnInit {
  @ViewChild('dialog') dialog: TemplateRef<NbDialogContainerComponent>;

  @Input() location = 'hours'; // hours, wholesale, wholesale_cimato
  storeOpen = false;
  isBrowser = true;
  currentHours = '5:00 pm';
  allHours = {};
  displayHours = 'Call For Hours <br/> 716.689.6764';
  constructor(
    public analyticsService: AnalyticsService,
    private helpService: HelpService,
    private dialogService: NbDialogService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  async ngOnInit(): Promise<void> {
    if (this.isBrowser) {
      try {
        const hours = JSON.parse(
          (await this.helpService.getRemoteConfigData(this.location)) || '{}'
        );

        const now = new Date();
        let openTime = '9:00pm';
        let closeTime = '5:00pm';
        this.allHours = hours;
        this.currentHours = this.parseCurrentHoursForDay(hours, new Date().getDay());
        if (this.currentHours) {
          openTime = this.currentHours.split('-').shift().trim().split(' ').shift();
          closeTime = this.currentHours.split('-').pop().trim().split(' ').shift();
          if (openTime.toLowerCase() !== 'closed' && closeTime.toLowerCase() !== 'closed') {
            this.storeOpen =
              now <
                new Date(
                  now.getFullYear(),
                  now.getMonth(),
                  now.getDate(),
                  Number(closeTime.split(':').shift()) + 12,
                  Number(closeTime.split(':').pop()),
                  0,
                  0
                ) &&
              now >
                new Date(
                  now.getFullYear(),
                  now.getMonth(),
                  now.getDate(),
                  Number(
                    Number(openTime.split(':').shift()) > 11 ? 0 : openTime.split(':').shift()
                  ),
                  Number(openTime.split(':').pop()),
                  0,
                  0
                );
          }
          if (!this.storeOpen) {
            let tomorrow = new Date().getDay();
            tomorrow++;
            if (tomorrow === 7) {
              tomorrow = 0;
            }
            this.currentHours =
              this.parseCurrentHoursForDay(hours, tomorrow) || '9:00 am - 5:00 pm';
            const split = this.currentHours.split('-');
            // set current hours to tomorrows open
            this.currentHours = split.length > 0 ? split[0].trim() : this.currentHours;
            if (this.currentHours.toLowerCase() === 'closed') {
              this.currentHours = '';
            }
          }
          // create hours display
          if (this.allHours['call']) {
            this.currentHours = '';
          } else {
            this.currentHours = this.currentHours.split('-').pop().trim();
            this.displayHours = this.parseForDisplayHoursHTML(this.allHours);
          }
        }
      } catch (error) {
        console.error('Error fetching hours from remote config', error);
      }
    }
  }

  open(): void {
    this.dialogService.open(this.dialog, {
      hasScroll: true,
      autoFocus: false,
      closeOnBackdropClick: true,
      closeOnEsc: true,
      context: 'this is some additional data passed to dialog'
    });
  }

  private parseCurrentHoursForDay(hours, dayIndex: number): string {
    switch (dayIndex) {
      case 0:
        return hours['sunday'];
      case 1:
        return hours['monday'];
      case 2:
        return hours['tuesday'];
      case 3:
        return hours['wednesday'];
      case 4:
        return hours['thursday'];
      case 5:
        return hours['friday'];
      case 6:
        return hours['saturday'];
    }
    return 'Closed';
  }

  private parseForDisplayHoursHTML(hours: any): string {
    const days = Object.keys(hours).map((day) => {
      return `${day.charAt(0).toUpperCase()}${day.substring(1)}`;
    });
    const values = Object.values(hours);
    let hoursHTML = '';
    let lastWrittenIndex = 0;
    days.forEach((day, index) => {
      if (index > 0 && index < 7) {
        if (values[index] !== values[index - 1]) {
          let label = days[lastWrittenIndex];
          if (index - 1 !== lastWrittenIndex) {
            label += ` - ${days[index - 1]}`;
          }
          hoursHTML += `${label}: ${values[index - 1]}<br/>`;
          lastWrittenIndex = index;
        }
      }
    });
    while (lastWrittenIndex <= 6 && values.length >= 6) {
      // end
      let label = days[lastWrittenIndex];
      if (lastWrittenIndex < 6 && values[lastWrittenIndex] === values[lastWrittenIndex + 1]) {
        label += ` - ${days[lastWrittenIndex + 1]}`;
        lastWrittenIndex++;
      }
      hoursHTML += `${label}: ${values[lastWrittenIndex]}<br/>`;
      lastWrittenIndex++;
    }
    return hoursHTML;
  }
}
