import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  desiredPage = '';
  constructor(private analyticsService: AnalyticsService, private router: Router) {}

  async ngOnInit(): Promise<void> {
    const navs = this.analyticsService.getNavUrls();
    this.desiredPage = navs.end;
    await this.analyticsService.logEvent('not_found', {
      page: this.desiredPage,
      missingPage: this.desiredPage
    });
  }
}
