import { isPlatformBrowser } from '@angular/common';
import { Component, Input, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { Slide } from 'shared';
import { trigger, transition, useAnimation } from '@angular/animations';

import {
  AnimationType,
  scaleIn,
  scaleOut,
  fadeIn,
  fadeOut,
  flipIn,
  flipOut,
  jackIn,
  jackOut
} from './carousel.animations';
import { timer, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [
    trigger('slideAnimation', [
      /* scale */
      transition('void => scale', [useAnimation(scaleIn, { params: { time: '500ms' } })]),
      transition('scale => void', [useAnimation(scaleOut, { params: { time: '500ms' } })]),

      /* fade */
      transition('void => fade', [useAnimation(fadeIn, { params: { time: '500ms' } })]),
      transition('fade => void', [useAnimation(fadeOut, { params: { time: '500ms' } })]),

      /* flip */
      transition('void => flip', [useAnimation(flipIn, { params: { time: '500ms' } })]),
      transition('flip => void', [useAnimation(flipOut, { params: { time: '500ms' } })]),

      /* JackInTheBox */
      transition('void => jackInTheBox', [useAnimation(jackIn, { params: { time: '700ms' } })]),
      transition('jackInTheBox => void', [useAnimation(jackOut, { params: { time: '700ms' } })])
    ])
  ]
})
export class CarouselComponent implements OnInit, OnDestroy {
  @Input() slides: Slide[] = [];
  @Input() animationType: AnimationType = AnimationType.Fade;
  @Input() autoAdvanceMs: number;

  $destroy = new Subject<boolean>();

  currentSlide = 0;
  skipFromUserInteration = false;
  isBrowser = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  onPreviousClick(): void {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    this.skipFromUserInteration = true;
  }

  onNextClick(): void {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
    this.skipFromUserInteration = true;
  }

  goToSlide(slideIndex: number): void {
    const next = 0 + slideIndex;
    this.currentSlide = next === this.slides.length ? 0 : next;
    this.skipFromUserInteration = true;
  }

  ngOnInit(): void {
    if (this.autoAdvanceMs && this.isBrowser) {
      timer(this.autoAdvanceMs, this.autoAdvanceMs)
        .pipe(takeUntil(this.$destroy))
        .subscribe(() => {
          if (this.skipFromUserInteration) {
            this.skipFromUserInteration = false;
          } else {
            const next = this.currentSlide + 1;
            this.currentSlide = next === this.slides.length ? 0 : next;
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
  }
}
