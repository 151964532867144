import { SharedModule } from '../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GallerySearchbarComponent } from './gallery-searchbar/gallery-searchbar.component';
import {
  NbAutocompleteModule,
  NbButtonModule,
  NbCardModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule
} from '@nebular/theme';
import { GalleryRecordDialogComponent } from './gallery-record-dialog/gallery-record-dialog.component';
import { GalleryRecordCardComponent } from './gallery-record-card/gallery-record-card.component';
import { GalleryRecordDetailComponent } from './gallery-record-detail/gallery-record-detail.component';
import { GallerySearchResultsComponent } from './gallery-search-results/gallery-search-results.component';
import { ShopComponentsModule } from './../../../shop/shop-components/shop-components.module';

@NgModule({
  declarations: [
    GallerySearchbarComponent,
    GalleryRecordDialogComponent,
    GalleryRecordCardComponent,
    GalleryRecordDetailComponent,
    GallerySearchResultsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ShopComponentsModule,
    NbButtonModule,
    NbFormFieldModule,
    NbIconModule,
    NbInputModule,
    NbCardModule,
    NbAutocompleteModule,
    NbDialogModule
  ],
  exports: [
    GallerySearchbarComponent,
    GalleryRecordDialogComponent,
    GalleryRecordCardComponent,
    GalleryRecordDetailComponent,
    GallerySearchResultsComponent
  ]
})
export class GalleryComponentsModule {}
