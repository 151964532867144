import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { NbSelectComponent } from '@nebular/theme';
import { Observable, Subject } from 'rxjs';
import { delay, share, map, shareReplay } from 'rxjs/operators';

@Injectable()
export class LayoutService {
  // used to subscribe to whenever layout is changed
  protected layoutSize$ = new Subject();
  $isHandset: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.XSmall])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  $isFullscreen: Observable<boolean> = this.breakpointObserver
    .observe([
      Breakpoints.Web,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver) {}

  changeLayoutSize(): void {
    this.layoutSize$.next(true);
  }

  onChangeLayoutSize(): Observable<any> {
    return this.layoutSize$.pipe(share(), delay(1));
  }

  // fixes issue: https://stackoverflow.com/questions/47782890/angular-material-dialog-component-hides-all-my-website-components
  // https://github.com/akveo/ngx-admin/issues/2076
  setScrollStrategyOnSelectComponent(
    selector: NbSelectComponent,
    scrollStrategy: 'noop' | 'close' | 'block' | 'reposition'
  ) {
    selector.scrollStrategy = scrollStrategy;
  }
}
