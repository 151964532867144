<div class="row middle-xs">
  <div class="col-xs-12 col-sm-8">
    <nb-user
      size="giant"
      shape="round"
      [name]="cartItem.price_data.product_data?.name"
      [title]="cartItem.price_data.unit_amount | currency"
      [picture]="
        cartItem.price_data.product_data.images?.length > 0
          ? cartItem.price_data.product_data.images[0]
          : undefined
      "
      [badgeText]="cartItem.quantity"
      badgeStatus="info"
      badgePosition="bottom right"
    >
    </nb-user>
  </div>
  <div class="col-xs-12 col-sm-4 text-right">
    <p class="h5 no-margin">
      {{ cartItem.price_data.unit_amount * cartItem.quantity | currency }}
    </p>
    <app-delete-button (delete)="deleteCartItem()"></app-delete-button>
  </div>
  <div class="col-xs-12 col-sm-4">
    <nb-actions size="tiny" fullWidth>
      <nb-action>
        <label>Qty: </label>
        <input
          [(ngModel)]="cartItem.quantity"
          placeholder="qty"
          fieldSize="tiny"
          [min]="minQty"
          [max]="maxQty"
          step="1"
          nbInput
          type="number"
        />
      </nb-action>
      <nb-action>
        <button (click)="updateCartItem()" size="tiny" ghost status="success" nbButton>
          <nb-icon icon="save-outline" status="success"></nb-icon>
          Update
        </button>
      </nb-action>
    </nb-actions>
  </div>
  <div class="col-xs-12">
    <p>Shipping Option</p>
    <nb-radio-group disabled name="{{ cartItem.id }}" [(ngModel)]="cartItem.retrievalMethodType">
      <nb-radio value="delivery">
        <nb-icon icon="car-outline" status="success"></nb-icon> Delivery</nb-radio
      >
      <nb-radio disabled value="pickup"
        ><nb-icon icon="home-outline"></nb-icon>Store Pickup</nb-radio
      >
    </nb-radio-group>
  </div>
</div>
