<button
  *ngIf="cart$ | async as cart"
  nbButton
  size="large"
  shape="round"
  ghost
  routerLink="/shop/cart"
>
  Cart
  <nb-icon icon="shopping-cart-outline"></nb-icon>
  <nb-badge
    text="{{ cartItemCount }}"
    position="top right"
    [ngClass]="{ pulse: cartItemCount > 0 }"
    [status]="cartItemCount > 0 ? 'success' : ''"
  >
  </nb-badge>
</button>
