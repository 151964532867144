import { HoursStatusComponent } from './../hours-status/hours-status.component';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { MENU_ITEMS } from './menu-items';
import { takeUntil, map, switchMap } from 'rxjs/operators';
import { Component, OnInit, ViewChild, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import {
  NbThemeService,
  NbSidebarService,
  NbMenuItem,
  NbMenuService,
  NbLayoutScrollService
} from '@nebular/theme';
import { LayoutService } from './../../services/layout.service';
import { Subject, Observable, fromEvent } from 'rxjs';
import { ShopService } from 'src/app/shop/shop.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
  @ViewChild('footerHours') footerHours: HoursStatusComponent;

  themes = [
    {
      value: 'default',
      name: 'Light'
    },
    {
      value: 'dark',
      name: 'Dark'
    }
  ];

  currentTheme = 'default';
  menu: NbMenuItem[] = MENU_ITEMS;
  userMenu: NbMenuItem[] = [{ title: 'Logout' }];
  claims: {
    [key: string]: any;
  } = { roles: [] };
  $destroy = new Subject<boolean>();
  $menuClickDestroy = new Subject<boolean>();

  clickObservable: Observable<Event>;

  clickX;
  clickY;
  lastMenuItemSelected: NbMenuItem;
  menuClickSubscription;

  isHeaderFixed = false;
  isShopEnabled = false;
  isHandset = true;
  shellInitialized = false;

  constructor(
    public themeService: NbThemeService,
    public sidebarService: NbSidebarService,
    public scrollService: NbLayoutScrollService,
    public authService: AuthService,
    public layoutService: LayoutService,
    private shopService: ShopService,
    public router: Router,
    private menuService: NbMenuService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  async ngOnInit(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      this.clickObservable = fromEvent(document, 'click');
      this.layoutService.$isHandset.pipe(takeUntil(this.$destroy)).subscribe((isHandset) => {
        this.isHandset = isHandset;
      });
    }
    this.shopService
      .shopSettings$()
      .pipe(takeUntil(this.$destroy))
      .subscribe((settings) => {
        this.isShopEnabled = settings.isShopEnabled;
        // clear to prevent multiple
        this.menu = this.menu.filter((m) => {
          return m.title !== 'Shop Online';
        });
        if (this.isShopEnabled) {
          this.menu.push({
            title: 'Shop Online',
            icon: 'shopping-cart-outline',
            link: '/shop/collection/mulch soil and stone'
          });
        }
      });
    this.scrollService
      .onScroll()
      .pipe(
        takeUntil(this.$destroy),
        switchMap(() => {
          this.sidebarService.collapse('menu-sidebar');
          return this.scrollService.getPosition();
        })
      )
      .subscribe((scrollPos) => {
        this.isHeaderFixed = scrollPos.y > 110;
      });

    this.menuService
      .onItemSelect()
      .pipe(takeUntil(this.$destroy))
      .subscribe(async () => {
        this.sidebarService.collapse('menu-sidebar');
      });
    this.menuService
      .onSubmenuToggle()
      .pipe(takeUntil(this.$destroy))
      .subscribe((select) => {
        this.lastMenuItemSelected = select.item;
        if (
          isPlatformBrowser(this.platformId) &&
          !this.menuClickSubscription &&
          this.clickObservable
        ) {
          this.menuClickSubscription = this.clickObservable
            .pipe(takeUntil(this.$menuClickDestroy))
            .subscribe((click: MouseEvent) => {
              if (!this.lastMenuItemSelected) {
                // clicked off
                //this.menuService.collapseAll();
                this.$menuClickDestroy.next(true);
                this.menuClickSubscription = undefined;
              }
              this.lastMenuItemSelected = undefined;
            });
        }
      });
    this.menuService
      .onItemClick()
      .pipe(map(({ item: { title } }) => title))
      .subscribe(async (title) => {
        switch (title) {
          case 'Logout':
            await this.logout();
            break;
          case 'Store Hours':
            // store hours clicked
            if (this.footerHours) {
              this.footerHours.open();
            }
            break;
          default:
            break;
        }
      });
    this.shellInitialized = true;
  }

  async ngOnDestroy(): Promise<void> {
    this.$destroy.next(true);
    this.$menuClickDestroy.next(true);
  }

  saveThemePreference(themeName: string): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('theme', themeName);
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(false, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  async logout(): Promise<void> {
    await this.router.navigateByUrl('/home');
    await this.authService.logout();
  }
}
