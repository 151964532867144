import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartButtonComponent } from './cart-button/cart-button.component';
import { AddToCartComponent } from './add-to-cart/add-to-cart.component';
import { GuestShopLoginComponent } from './guest-shop-login/guest-shop-login.component';
import {
  NbActionsModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbUserModule
} from '@nebular/theme';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CartItemComponent } from './cart-item/cart-item.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddressIntakeComponent } from './address-intake/address-intake.component';

const shopComponents = [
  CartButtonComponent,
  AddToCartComponent,
  GuestShopLoginComponent,
  CartItemComponent,
  AddressIntakeComponent
];
@NgModule({
  declarations: [...shopComponents],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    RouterModule,
    NbActionsModule,
    NbCardModule,
    NbRadioModule,
    NbBadgeModule,
    NbUserModule,
    NbSelectModule,
    NbInputModule,
    NbButtonModule,
    NbIconModule
  ],
  exports: [...shopComponents]
})
export class ShopComponentsModule {}
