import { AnalyticsService } from './services/analytics.service';
import { SeoService } from './services/seo.service';
import { NbThemeService } from '@nebular/theme';
import { Component, OnInit } from '@angular/core';
import { HelpService } from './services/help.service';
import { NgxSpinnerService } from 'ngx-spinner';

declare var snowStorm: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'retail';
  theme = 'default';

  constructor(
    public themeService: NbThemeService,
    private seoSvc: SeoService,
    private analyticsSvc: AnalyticsService,
    private spinner: NgxSpinnerService,
    private helpService: HelpService
  ) {
    this.seoSvc.initSEO();
  }

  async ngOnInit(): Promise<void> {
    try {
      await this.analyticsSvc.enable();
      this.theme = (await this.helpService.getRemoteConfigData('theme')) || 'default';
      this.themeService.changeTheme(this.theme);
      if (this.theme === 'fall') {
        this.helpService.themeGalleryURL = '/gallery/collection/seasonal/category/seasonal-fall';
        this.helpService.themeItems = ['Pumpkins', 'Mums', 'Hay Bales', 'Corn Stalks'];
      } else if (this.theme === 'winter') {
        this.helpService.themeGalleryURL = '/gallery/collection/seasonal/category/seasonal-winter';
        this.helpService.themeItems = [
          `Fraser Fir`,
          `Douglas Fir`,
          `Concolor Fir`,
          `Scotch Pine`,
          `Colorado Spruce`,
          `Tree Stands`,
          `Tree Skirts`,
          `Easy Clean Tree Bags`,
          `Live Wreaths`,
          `Wreath Services`,
          `Custom Wreaths`,
          `Christmas Lights`,
          `Fraser Roping`,
          `White Pine Roping`,
          `Christmas Ornaments`,
          `Festive Stockings`
        ];
      }
    } catch (error) {
      console.error('Error starting: ', error);
    }
  }
}
