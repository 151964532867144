import { map, take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { GalleryRecord } from 'shared';
import { Observable } from 'rxjs';
import { FeaturedItem } from 'shared';
import firebase from 'firebase/compat/app';

export interface GalleryCategoryFilter {
  fieldPath: string | firebase.firestore.FieldPath;
  opStr: firebase.firestore.WhereFilterOp;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  galleryRecordMetadata = null;

  constructor(private afs: AngularFirestore) {}

  async fetchMetadata(): Promise<void> {
    if (!this.galleryRecordMetadata) {
      this.galleryRecordMetadata = (
        await this.afs
          .collection('collection-metadata')
          .doc('gallery-records-2023')
          .get()
          .pipe(take(1))
          .toPromise()
      ).data();
    }
  }

  keywordSearch(keyword: string, limit = 3): Observable<GalleryRecord[]> {
    return this.afs
      .collection<GalleryRecord>('gallery-records', (ref) => {
        return ref
          .where('hasPhotos', '==', true)
          .where('searchTerms', 'array-contains', keyword.toLowerCase())
          .limit(limit);
      })
      .get()
      .pipe(
        map((records) => {
          return records.docs.map((record) => {
            if (record.exists) {
              return record.data() as GalleryRecord;
            }
          });
        })
      );
  }

  getKeywordSearchObservable(
    keyword: string,
    rowItems = 3,
    cursors?: Map<number, any[]>
  ): Observable<GalleryRecord[][]> {
    return this.afs
      .collection<GalleryRecord>('gallery-records', (ref) => {
        let r = ref
          .where('hasPhotos', '==', true)
          .where('searchTerms', 'array-contains', keyword.toLowerCase().trim())
          .orderBy('name', 'asc');
        if (cursors && cursors.has(0)) {
          const cursor = cursors.get(0).pop().name;
          r = r.startAfter(cursor);
        }
        return r.limit(15);
      })
      .valueChanges()
      .pipe(
        map((rec) => {
          let row = [];
          const records = [];
          rec.forEach((r, index) => {
            if (index !== 0 && index % rowItems === 0) {
              records.push([...row]);
              row = [];
            }
            row.push(r);
          });
          if (row.length > 0) {
            records.push([...row]);
          }
          return records;
        })
      );
  }

  getCategoryScrollerObservable(
    parentCollection: string,
    category: string,
    rowItems = 3,
    filters: GalleryCategoryFilter[] = [],
    cursors?: Map<number, any[]>
  ): Observable<GalleryRecord[][]> {
    return this.afs
      .collection<GalleryRecord>('gallery-records', (ref) => {
        let r = ref
          .where('hasPhotos', '==', true)
          .where('galleryCollection', '==', parentCollection)
          .where('category', '==', category);

        filters.forEach((f) => {
          r = r.where(f.fieldPath, f.opStr, f.value);
        });

        r = r.orderBy('name', 'asc');

        if (cursors && cursors.has(0)) {
          const cursor = cursors.get(0).pop().name;
          r = r.startAfter(cursor);
        }
        return r.limit(20);
      })
      .valueChanges()
      .pipe(
        map((rec) => {
          let row = [];
          const records: GalleryRecord[][] = [];
          rec.forEach((r, index) => {
            if (index !== 0 && index % rowItems === 0) {
              records.push([...row]);
              row = [];
            }
            row.push(r);
          });
          if (row.length > 0) {
            records.push([...row]);
          }
          return records;
        })
      );
  }

  getGalleryRecordById(id): Promise<GalleryRecord> {
    return this.afs
      .collection<GalleryRecord>('gallery-records')
      .doc(id)
      .get()
      .pipe(
        take(1),
        map((record) => {
          return record.data();
        })
      )
      .toPromise();
  }

  getFeaturedItem$(): Observable<FeaturedItem> {
    return this.afs.collection('featured-items').doc<FeaturedItem>('MAIN').valueChanges();
  }

  getRecentlyFeaturedItems$(limit = 8): Observable<FeaturedItem[]> {
    return this.afs
      .collection<FeaturedItem>('featured-items', (ref) => {
        return ref.orderBy('createdAt', 'desc').limit(limit);
      })
      .valueChanges();
  }
}
