<!-- <p><strong>Delivery Address</strong></p>

<div class="row">
  <div class="col-xs-12">
    <label for="addressOne">Address</label>
    <input
      type="text"
      [(ngModel)]="address.addressOne"
      fullWidth
      nbInput
      (keydown)="modified.emit(true)"
      shape="round"
      fieldSize="small"
      id="addressOne"
      name="addressOne"
      placeholder="Enter Your Street Address"
    />
  </div>
  <div class="col-xs-12">
    <label for="addressTwo">Address 2</label>
    <input
      type="text"
      [(ngModel)]="address.addressTwo"
      (keydown)="modified.emit(true)"
      fullWidth
      nbInput
      shape="round"
      fieldSize="small"
      id="addressTwo"
      name="addressTwo"
      placeholder="Apt/Suite/Unit"
    />
  </div>
  <div class="col-xs-12">
    <label for="city">City</label>
    <input
      type="text"
      [(ngModel)]="address.city"
      fullWidth
      nbInput
      (keydown)="modified.emit(true)"
      shape="round"
      fieldSize="small"
      id="city"
      name="city"
      placeholder="City"
    />
  </div>
  <div class="col-xs-6">
    <label for="state">State</label>
    <nb-select
      disabled
      [(ngModel)]="address.state"
      shape="round"
      fullWidth
      name="name"
      placeholder="State"
    >
      <nb-option value="ny">NY</nb-option>
    </nb-select>
  </div>
  <div class="col-xs-6">
    <label for="zip">Zip</label>
    <input
      type="text"
      [(ngModel)]="address.zip"
      (keydown)="modified.emit(true)"
      fullWidth
      nbInput
      shape="round"
      fieldSize="small"
      id="zip"
      name="zip"
      placeholder="Zip"
    />
  </div>
</div> -->

<!-- <nb-actions
  size="tiny"
  fullWidth=""
  *ngIf="address && address.addressOne && address.city && address.zip"
>
  <nb-action>
    <button (click)="updated.emit(address)" size="large" ghost status="success" nbButton>
      <nb-icon icon="save-outline" status="success"></nb-icon>
      Submit Address
    </button>
  </nb-action>
</nb-actions> -->

<label for="zip">Zip: </label>
<input
  type="text"
  [(ngModel)]="address.zip"
  (keydown)="modified.emit(true)"
  nbInput
  shape="round"
  fieldSize="small"
  id="zip"
  name="zip"
  placeholder="Delivery Zip"
/>
<button
  *ngIf="address && address.zip"
  (click)="updated.emit(address)"
  size="small"
  ghost
  status="success"
  nbButton
>
  <nb-icon icon="save-outline" status="success"></nb-icon>
  Update Zip
</button>

<p *ngIf="address && address.zip">
  We only deliver within the state of New York to select zip codes.
  <br />
  <strong>
    You must use this zip code at checkout for delivery or your order will be cancelled.
  </strong>
</p>
