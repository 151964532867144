<div class="wrapper">
  <div class="row center-xs around-xs">
    <div class="col-md-8">
      <div class="h6">Wholesale Corner Menu</div>
    </div>
  </div>
  <div class="row center-xs around-xs">
    <div class="col-md-10 no-padding">
      <nb-menu class="menu-horizontal h3" autoCollapse="true" [items]="items"></nb-menu>
    </div>
  </div>
</div>
