import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { map, filter, mergeMap, take } from 'rxjs/operators';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
    private router: Router,
    private meta: Meta,
    private titleService: Title,
    public sidebarService: NbSidebarService,
    private menuService: NbMenuService
  ) {}

  initSEO(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router.routerState.root),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        let active = this.router.routerState.root;
        while (active.firstChild) {
          active = active.firstChild;
        }
        const endParam = event.title && event.title.split(':').pop();
        if (typeof event.title === 'string' && active.snapshot.paramMap.get(endParam)) {
          // if end param exists in the param map (i.e. path variable)
          event.title =
            event.title.substring(0, event.title.indexOf(':')) +
            active.snapshot.paramMap.get(endParam);
        }
        this.setPageSEOMetaTags(event.description, event.title, event.imageURL);
        timer(400)
          .pipe(take(1))
          .subscribe(() => {
            this.menuService.collapseAll();
            this.sidebarService.collapse('menu-sidebar');
          });
      });
  }

  setPageSEOMetaTags(
    description: string,
    title: string = `Welcome`,
    imageURL: string = 'http://russellsny.com/assets/images/logo_seo.png'
  ): void {
    const url = `https://russellsny.com${this.router.url}`;
    title =
      title +
      `${
        title.length > 0 ? ' | ' : ''
      }Russell's Tree and Shrub Farm | WNY and Buffalo's Garden Center`;
    this.titleService.setTitle(title);
    // Set meta tags
    this.meta.updateTag({
      name: 'description',
      content: description
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: '@russellsny' });
    this.meta.updateTag({
      name: 'twitter:title',
      content: title
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content: description
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: imageURL
    });
    this.meta.updateTag({
      name: 'twitter:url',
      content: url
    });

    this.meta.updateTag({ name: 'og:card', content: 'summary' });
    this.meta.updateTag({ name: 'og:site', content: '@russellsny' });
    this.meta.updateTag({
      name: 'og:title',
      property: 'og:title',
      content: title
    });
    this.meta.updateTag({
      name: 'og:description',
      property: 'og:description',
      content: description
    });
    this.meta.updateTag({
      name: 'og:image',
      property: 'og:image',
      content: imageURL
    });
    this.meta.updateTag({
      name: 'og:image:width',
      content: '404'
    });
    this.meta.updateTag({
      name: 'og:image:height',
      content: '234'
    });
  }
}
