<p class="hours-wrapper" (click)="open()">
  {{ storeOpen ? 'Open' : 'Closed' }}
  until
  {{ currentHours }}
</p>
<button
  aria-label="hours status"
  (click)="open()"
  nbButton
  size="small"
  ghost
  [status]="storeOpen ? 'success' : 'danger'"
>
  <nb-icon icon="radio-button-on"></nb-icon>
</button>

<ng-template #dialog let-data let-ref="dialogRef">
  <div class="row hours-dialog">
    <div class="col-md-12">
      <nb-card accent="info" size="small">
        <nb-card-header>
          Store Hours
          <button aria-label="hours status" nbButton ghost (click)="ref.close()">
            <nb-icon status="primary" icon="close"></nb-icon>
          </button>
        </nb-card-header>
        <nb-card-body class="text-center text-hours">
          <span [innerHTML]="displayHours"></span>
          <span *ngIf="allHours['holiday'] && currentHours">
            <br />
            {{ allHours['holiday'] }}
          </span>
          <br />
          <p class="weather-notice">
            During severe inclement weather store hours are subject to change. Please call to
            verify.
            <br />
            Thank you for your understanding.
          </p>
        </nb-card-body>
        <nb-card-footer class="text-center">
          <a href="tel:7166896764">
            <button (click)="analyticsService.logEvent('call_hours')" shape="round" nbButton>
              <nb-icon status="success" icon="phone"></nb-icon>
              Call
            </button>
          </a>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</ng-template>
