<div
  class="dropzone"
  appDropzone
  (hovered)="toggleHover($event)"
  (dropped)="onDrop($event)"
  [class.hovering]="isHovering"
>
  <p>Drag n' Drop Images</p>
  <input
    class="inputfile"
    type="file"
    [name]="formName"
    [id]="formName"
    accept=".jpg, .jpeg, .png, .pdf, image/*"
    (change)="onDrop($event?.target?.files)"
    multiple
  />
  <label [for]="formName">Choose a file</label>
  <nb-alert *ngIf="files.length > 0" status="warning">Pending Uploads: {{ files.length }}</nb-alert>
  <div class="row">
    <div class="col-xs-6 col-sm-3 col-preview" *ngFor="let src of filePreviewSrc; let i = index">
      <img *ngIf="src.indexOf('.pdf') < 0" class="img-responsive" [src]="src" />
      <nb-alert *ngIf="src.indexOf('.pdf') > -1" status="info">Pending File Upload</nb-alert>
      <nb-badge (click)="removeImg(i)" text="X" status="danger" position="top right"></nb-badge>
    </div>
  </div>
</div>
