import { FileUploadComponent } from './file-upload/file-upload.component';
import { CarouselComponent } from './carousel/carousel.component';
import { LayoutService } from './../services/layout.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbLayoutModule,
  NbSidebarModule,
  NbButtonModule,
  NbToggleModule,
  NbActionsModule,
  NbUserModule,
  NbSelectModule,
  NbIconModule,
  NbMenuModule,
  NbContextMenuModule,
  NbAlertModule,
  NbInputModule,
  NbCardModule,
  NbFormFieldModule,
  NbBadgeModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RouterModule } from '@angular/router';
import { HoursStatusComponent } from './hours-status/hours-status.component';
import { DropzoneDirective } from './directives/dropzone.directive';
import { ConnectLinkComponent } from './connect-link/connect-link.component';
import { DocScrollerComponent } from './doc-scroller/doc-scroller.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { AltMenuComponent } from './alt-menu/alt-menu.component';
import { IFrameResizerDirective } from './directives/iframe-resizer.directive';
import { DeleteButtonComponent } from './delete-button/delete-button.component';

const components = [
  CarouselComponent,
  HoursStatusComponent,
  FileUploadComponent,
  DropzoneDirective,
  ConnectLinkComponent,
  BreadcrumbsComponent,
  DocScrollerComponent,
  AltMenuComponent,
  IFrameResizerDirective
];
const modules = [
  CommonModule,
  RouterModule,
  ScrollingModule,
  FormsModule,
  VirtualScrollerModule,
  NbLayoutModule,
  NbFormFieldModule,
  NbBadgeModule,
  NbIconModule,
  NbUserModule,
  NbActionsModule,
  NbCardModule,
  NbMenuModule,
  NbEvaIconsModule,
  NbInputModule,
  NbToggleModule,
  NbActionsModule,
  NbButtonModule,
  NbSidebarModule,
  NbSelectModule,
  NbAlertModule,
  NbContextMenuModule
];

@NgModule({
  declarations: [...components, DeleteButtonComponent],
  imports: [...modules],
  exports: [...components, DeleteButtonComponent],
  providers: [LayoutService]
})
export class SharedModule {}
