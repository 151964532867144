import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  CartItem,
  DeliveryRule,
  GalleryRecord,
  initLineItem,
  LineItem,
  ShopSettings
} from 'shared';
import { ShopService } from '../../shop.service';

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss']
})
export class AddToCartComponent implements OnInit {
  @ViewChild('checkout', { static: true }) checkoutDialog: TemplateRef<any>;

  @Input() lineItem: LineItem = initLineItem();
  @Input() galleryRecord: GalleryRecord;

  @Output() added = new EventEmitter<CartItem>();

  qty = 1;
  isShopEnabled = false;

  destroy$ = new Subject<boolean>();
  shopSettings$: Observable<ShopSettings>;

  retrievalMethodType: 'delivery' | 'ship' | 'pickup' = 'delivery';
  deliveryRule: DeliveryRule;

  minQty = 1;
  maxQty = 1;

  constructor(
    private shopService: ShopService,
    private router: Router,
    private dialogService: NbDialogService
  ) {}

  async ngOnInit(): Promise<void> {
    this.qty = this.lineItem.minQty;
    this.shopSettings$ = this.shopService.shopSettings$();
    this.retrievalMethodType = 'delivery';
    this.minQty = this.lineItem.minQty;
    this.maxQty = this.lineItem.maxQty;
    if (this.retrievalMethodType === 'delivery') {
      const rules = await this.shopService.deliveryRules$().pipe(take(1)).toPromise();
      rules.forEach((r) => {
        if (r.appliedGalleryCategories.indexOf(this.lineItem.galleryCategory) > -1) {
          this.deliveryRule = r;
          this.qty = r.minQty;
          this.minQty = Math.max(this.minQty, this.deliveryRule.minQty);
          this.maxQty = Math.min(this.maxQty, this.deliveryRule.maxQty);
        }
      });
      const url = this.router.parseUrl(this.router.url);
      if (url.queryParamMap.has('qty') && !isNaN(Number(url.queryParamMap.get('qty')))) {
        // if qty is sent in
        const input = Math.ceil(Number(url.queryParamMap.get('qty')));
        const min = Math.max(this.minQty, input);
        const value = Math.min(min, this.maxQty);
        this.qty = value;
      }
    }
  }

  async addToCart() {
    let thumb;
    if (
      this.galleryRecord &&
      this.galleryRecord.hasPhotos &&
      this.galleryRecord.thumbnails &&
      this.galleryRecord.thumbnails.length > 0
    ) {
      thumb = this.galleryRecord.thumbnails[0].downloadURL;
      this.lineItem.price_data.product_data.images = [thumb];
    }
    const cartItem = {
      ...this.lineItem,
      retrievalMethodType: this.retrievalMethodType,
      quantity: this.qty
    };
    if (!this.shopService.isValidItemQuantity(cartItem, this.deliveryRule)) {
      return;
    }
    try {
      const res = await this.shopService.addToCart([cartItem]);
      if (cartItem.retrievalMethodType === 'delivery' || cartItem.retrievalMethodType === 'ship') {
        // update slots
        await this.shopService.updateCartDeliverySlots();
      }
      this.added.emit(cartItem);
      // popup to go to checkout
      if (res) {
        this.dialogService
          .open(this.checkoutDialog)
          .onClose.subscribe(async (goToCheckout: boolean) => {
            if (goToCheckout) {
              await this.router.navigateByUrl('/shop/cart');
            }
          });
      }
    } catch (error) {}
  }
}
