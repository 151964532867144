import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeliveryAddress } from 'shared';

@Component({
  selector: 'app-address-intake',
  templateUrl: './address-intake.component.html',
  styleUrls: ['./address-intake.component.scss']
})
export class AddressIntakeComponent {
  @Output() updated = new EventEmitter<DeliveryAddress>();
  @Output() modified = new EventEmitter<boolean>();

  @Input() address: DeliveryAddress = {} as DeliveryAddress;

  constructor() {}
}
