import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  // optional input to auto upload
  @Input() destination = false;

  isHovering: boolean;
  files: File[] = [];
  filePreviewSrc: string[] = [];

  formName = `${Math.random()}`;

  toggleHover(event: boolean): void {
    this.isHovering = event;
  }

  onDrop(files: FileList): void {
    const me = this;
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));
      const reader = new FileReader();
      reader.onloadend = function () {
        const url = reader.result as string;
        me.filePreviewSrc.push(url);
      };
      reader.readAsDataURL(files[i]);
    }
  }

  removeImg(index: number) {
    this.files.splice(index, 1);
    this.filePreviewSrc.splice(index, 1);
  }

  clearAll() {
    this.files = [];
    this.filePreviewSrc = [];
  }
}
