import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';
import { AuthService } from 'src/app/services/auth.service';
import { ShopService } from '../../shop.service';

@Component({
  selector: 'app-guest-shop-login',
  templateUrl: './guest-shop-login.component.html',
  styleUrls: ['./guest-shop-login.component.scss']
})
export class GuestShopLoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private shopService: ShopService,
    public dialogRef: NbDialogRef<GuestShopLoginComponent>
  ) {}

  ngOnInit(): void {
    this.dialogRef.onClose.subscribe((res) => {
      if (!res) {
        this.shopService.resetCart();
      }
    });
  }

  async guestLogin() {
    await this.authService.createAnonymousUser();
    await this.shopService.initializeNewCart();
    await this.router.navigateByUrl('/shop/cart');
    await this.dialogRef.close(true);
  }
}
