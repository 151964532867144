import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-connect-link',
  templateUrl: './connect-link.component.html',
  styleUrls: ['./connect-link.component.scss']
})
export class ConnectLinkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
