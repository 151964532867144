import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  segments: any[] = [];

  constructor(public router: Router) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);

    const segments = url.root?.children?.primary?.segments;
    let path = '/';
    segments.forEach((seg, index) => {
      path += decodeURIComponent(seg.path) + '/';
      if (index === 0 || index % 2 === 0) {
        // display it
        this.segments.push({
          segment: seg.path,
          path: decodeURIComponent(path)
        });
      }
    });
  }
}
