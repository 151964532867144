import { NbMenuItem } from '@nebular/theme';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alt-menu',
  templateUrl: './alt-menu.component.html',
  styleUrls: ['./alt-menu.component.scss']
})
export class AltMenuComponent implements OnInit {
  items: NbMenuItem[] = [
    {
      title: 'Home',
      link: '/wholesale'
    },
    {
      title: 'Catalog',
      link: '/wholesale/catalog'
    },
    {
      title: 'Product Request',
      link: '/wholesale/requests'
    },
    {
      title: 'Policy',
      link: '/wholesale/policy'
    },
    {
      title: 'Feedback & Support',
      url: 'mailto:incoming+russellsny-website-16188431-issue-@incoming.gitlab.com'
    }
  ];
  constructor() {}

  ngOnInit(): void {}
}
