<a target="_blank" rel="noreferrer" href="http://eepurl.com/hfbG8P">
  <button nbButton shape="round" status="warning">
    <nb-icon icon="people"></nb-icon>
    Stay Connected
  </button>
</a>
<p>
  <a target="_blank" rel="noreferrer" href="https://facebook.com/russellsny">
    <button aria-label="facebook" size="giant" nbButton ghost status="info">
      <nb-icon icon="facebook"></nb-icon>
    </button>
  </a>
  <a target="_blank" rel="noreferrer" href="https://instagram.com/russellsny">
    <button aria-label="instagram" size="giant" nbButton ghost status="danger">
      <nb-icon icon="camera"></nb-icon>
    </button>
  </a>
</p>
