import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Location } from '@angular/common';

if (environment.production || environment.qa) {
  enableProdMode();
}

// Prevent stripping of trailing slash
Location.stripTrailingSlash = function stripTrailingSlashNew(url: string): string {
  return url;
};

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
