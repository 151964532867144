import { RouterModule } from '@angular/router';
import { GalleryComponentsModule } from '../../pages/gallery/gallery-components/gallery-components.module';
import { SharedModule } from './../shared.module';
import { ShellComponent } from './shell.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbLayoutModule,
  NbIconModule,
  NbSidebarModule,
  NbMenuModule,
  NbButtonModule,
  NbUserModule,
  NbContextMenuModule
} from '@nebular/theme';
import { ShopComponentsModule } from './../../shop/shop-components/shop-components.module';

@NgModule({
  declarations: [ShellComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    GalleryComponentsModule,
    NbLayoutModule,
    NbIconModule,
    NbSidebarModule,
    NbUserModule,
    NbContextMenuModule,
    NbMenuModule,
    NbButtonModule,
    ShopComponentsModule
  ],
  exports: [ShellComponent]
})
export class ShellModule {}
