<p class="breadcrumb">
  <ng-container *ngFor="let seg of segments; let i = index">
    <a
      [routerLink]="seg.path"
      *ngIf="i < segments.length - 1 || segments.length === 1; else current"
    >
      {{ seg.segment }} /
    </a>
    <ng-template #current>
      <span class="text-bold">{{ seg.segment }}</span>
    </ng-template>
  </ng-container>
</p>
